<template>
    <div>
        <v-container>
            <v-row>
                <v-col col="8">
                    <select-customer v-on:customer-selected="customerSelected" v-if="!isCustomerUser"></select-customer>
                    <h3 v-else>
                        {{ this.$store.getters.customer }}
                    </h3>
                </v-col>
                <v-col col="4" sm="4">
                    <v-toolbar flat color="transparent">
                        <v-spacer></v-spacer>
                        <v-menu :close-on-content-click="false" min-width="400px" rounded left>
                            <template v-slot:activator="{ on }">
                                <v-btn class="my-3 float-left" icon small v-on="on">
                                    <v-avatar color="green" size="30">
                                        <v-icon dark>mdi-settings</v-icon>
                                    </v-avatar>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-form ref="settingsForm" v-model="valid" lazy-validation>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-select v-model="filter.industryType" :items="industryTypeOptions"
                                                    label="Industry Type" item-text="englishName" item-value="id"
                                                    @change="industryTypeChanged">
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-select v-model="filter.businessSector" :items="businessSectorsOptions"
                                                    label="Business Sector" no-data-text="no items" item-text="englishName"
                                                    item-value="id" :disabled="filter.industryType == null"
                                                    @change="businessSectorChanged">
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-select v-model="filter.businessSubSector"
                                                    :items="businessSubSectorsOptions" label="Sub-Sector"
                                                    item-text="englishName" item-value="id"
                                                    :disabled="filter.businessSector == null">
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <!-- <v-row>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-select v-model="filter.legalStructure" :items="legalStructureOptions"
                                                    label="Legal Structure">
                                                </v-select>
                                            </v-col>
                                        </v-row> -->
                                        <v-row>
                                            <v-col cols="3" sm="3" md="3">
                                                Years of Experience
                                            </v-col>
                                            <v-col cols="4" sm="4" md="4">
                                                <v-text-field v-model="filter.experienceYearsFrom"
                                                    :rules="[rules.experienceYearsFrom]" min=0 type="number"
                                                    append-icon="mdi-magnify" label="From" single-line hide-details>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="4" sm="4" md="4">
                                                <v-text-field v-model="filter.experienceYearsTo"
                                                    :rules="[rules.experienceYearsTo]" min=0 type="number"
                                                    append-icon="mdi-magnify" label="To" single-line hide-details>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <!-- <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-range-slider
                                                v-model="range"
                                                :max="max"
                                                :min="min"
                                                hide-details
                                                class="align-center"
                                            >
                                                 <template v-slot:prepend>
                                                    <v-text-field
                                                        :value="range[0]"
                                                        class="mt-0 pt-0"
                                                        hide-details
                                                        single-line
                                                        type="number"
                                                        style="width: 60px"
                                                        @change="$set(range, 0, $event)"
                                                    ></v-text-field>
                                                    </template>
                                                    <template v-slot:append>
                                                    <v-text-field
                                                        :value="range[1]"
                                                        class="mt-0 pt-0"
                                                        hide-details
                                                        single-line
                                                        type="number"
                                                        style="width: 60px"
                                                        @change="$set(range, 1, $event)"
                                                    ></v-text-field>
                                                    </template>
                                            </v-range-slider>
                                        </v-col>
                                    </v-row> -->
                                        <v-row>
                                            <v-col cols="3" sm="3" md="3">
                                                Revenue
                                            </v-col>
                                            <v-col cols="4" sm="4" md="4">
                                                <v-text-field v-model="filter.revenueFrom" append-icon="mdi-magnify"
                                                    :rules="[rules.revenueFrom]" min=0 type="number" label="From"
                                                    single-line hide-details>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="4" sm="4" md="4">
                                                <v-text-field v-model="filter.revenueTo" append-icon="mdi-magnify"
                                                    :rules="[rules.revenueTo]" min=0 type="number" label="To" single-line
                                                    hide-details>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="3" sm="3" md="3">
                                                Headcount
                                            </v-col>
                                            <v-col cols="4" sm="4" md="4">
                                                <v-text-field v-model="filter.headcountFrom" append-icon="mdi-magnify"
                                                    :rules="[rules.headcountFrom]" min=0 type="number" label="From"
                                                    single-line hide-details>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="4" sm="4" md="4">
                                                <v-text-field v-model="filter.headcountTo" append-icon="mdi-magnify"
                                                    :rules="[rules.headcountTo]" min=0 type="number" label="To" single-line
                                                    hide-details>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="3" sm="3" md="3">
                                                Number Of Services
                                            </v-col>
                                            <v-col cols="4" sm="4" md="4">
                                                <v-text-field v-model="filter.numOfServicesFrom" append-icon="mdi-magnify"
                                                    :rules="[rules.numOfServicesFrom]" label="From" min=0 type="number"
                                                    single-line hide-details>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="4" sm="4" md="4">
                                                <v-text-field v-model="filter.numOfServicesTo"
                                                    :rules="[rules.numOfServicesTo]" append-icon="mdi-magnify" label="To"
                                                    min=0 type="number" single-line hide-details>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-btn color="green darken-1" :disabled="reportData.length == 0" text
                                                    @click="validateFilter">Apply</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-card>
                        </v-menu>
                    </v-toolbar>
                </v-col>
            </v-row>
        </v-container>

        <!--
        <v-form ref="form" v-model="valid" lazy-validation v-if="!isCustomerUser">
            <v-container>
                <v-row>
                    <v-col col="12" sm="4">
                        <v-menu :close-on-content-click="false" :nudge-width="200" ref="searchMenu" offset-x>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="green" dark v-bind="attrs" class="mt-2 ml-2" v-on="on">
                                    <v-icon class="mr-2" large>mdi-account</v-icon>
                                    {{ selectedCustomer.text }}
                                </v-btn>
                            </template>
                            <v-card class="mx-auto" max-width="500">
                                <v-toolbar flat color="transparent">
                                    <v-toolbar-title>Select Customer</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="$refs.searchMenu.focus()">
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-container class="py-0">
                                    <v-row align="center" justify="start">
                                        <v-col v-for="(selection, i) in selections" :key="selection.text" class="shrink">
                                            <v-chip :disabled="loading" close @click:close="selected.splice(i, 1)">
                                                <v-icon left v-text="selection.icon"></v-icon>
                                                {{ selection.text }}
                                            </v-chip>
                                        </v-col>
                                        <v-col v-if="!allSelected" cols="12">
                                            <v-text-field ref="search" v-model="searchItem" full-width hide-details
                                                label="Search" single-line></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-list>
                                    <template v-for="item in categories">
                                        <v-list-item :key="item.customerNumber" :disabled="loading"
                                            v-if="!selected.includes(item)" @click="SetselectedCustomer(item)">
                                            <v-list-item-avatar>
                                                <v-icon :disabled="loading" v-text="item.icon"></v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-title v-text="item.text"></v-list-item-title>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-card>
                        </v-menu>
                    </v-col>
                    <v-col col="12" sm="4">
                        <v-btn class="ma-2" outlined small fab color="green">
                            <v-icon @click="CustomerProfileSearch()">mdi-magnify</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form> -->

        <v-container>
            <v-card flat class="mx-auto" max-width="1000">

            </v-card>
        </v-container>


        <v-container>
            <v-dialog v-model="filterDialog" max-width="500px">
                <v-card elevation="10">
                    <v-card-title>
                        Filter parameters
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="FilterForm" v-model="valid" lazy-validation>
                            <v-container>
                                <!-- <v-row>
                                <v-col cols="12" sm="6" md="6">
                                        <v-select
                                        v-model="search.economicSector"
                                        :items="allEconomicSectors"
                                        label="Economic Sector"
                                        item-text="englishName"
                                         @change="economicSectorChanged"
                                        item-value="id">
                                    </v-select>
                                </v-col>
                            </v-row> -->
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-select v-model="search.industryType" :items="industryTypeOptions"
                                            label="Industry Type" item-text="englishName" @change="industryTypeChanged"
                                            item-value="id">
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-select v-model="search.businessSector" :items="businessSectorOptions"
                                            label="Business Sector" item-text="englishName"
                                            :disabled="search.industryType == null" @change="businessSectorChanged"
                                            item-value="id">
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-select v-model="search.businessSubSector" :items="businessSubSectorOptions"
                                            label="Business Sub-Sector" item-text="englishName"
                                            :disabled="search.businessSector == null" item-value="id">
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-select v-model="search.jobFamily" :items="allJobFamilies" label="Job Family"
                                            item-text="englishName" @change="jobFamilyChanged" item-value="id">
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-select v-model="search.position" :items="positionOptions" label="Position"
                                            item-text="englishDescription" item-value="id">
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="filterDialog = false"> Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="applyFilter">Apply</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>

        <b-container fluid>
            <!-- <b-col md="3" >
                    <b-card
                    header="Search Criteria"
                    header-tag="header">
                        <form ref="form" @submit.stop.prevent="Search">
                             <b-row class="my-0" v-if="customerNumber<=0">
                                <b-col sm="12" >
                                    <label for="ddlCustomersList">Subscription</label>
                                </b-col>
                            </b-row>
                            <b-row class="my-0" v-if="customerNumber<=0">
                                <b-col sm="12" >
                                    <b-form-select
                                            id="ddlCustomersList"
                                            v-model="search.selectedSubscription"
                                            :options="customersList"
                                            text-field="customerName"
                                            value-field="customerNumber">
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                </b-col>
                            </b-row>
                             <b-row class="my-0">
                                <b-col sm="12" >
                                    <label for="ddlEconomicSector">Economic Sector :</label>
                                </b-col>
                            </b-row>
                            <b-row class="my-0">
                                <b-col sm="12" >
                                    <b-form-select
                                            id="ddlEconomicSector"
                                            v-model="search.economicSector"
                                            :options="allEconomicSectors"
                                            text-field="englishName"
                                            @change="economicSectorChanged"
                                            value-field="id">
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                </b-col>
                            </b-row>
                                <b-row class="my-0">
                                <b-col sm="12" >
                                    <label for="ddlIndustryType">Industry Type :</label>
                                </b-col>
                            </b-row>
                                <b-row class="my-0">
                                <b-col sm="12" >
                                    <b-form-select
                                            id="ddlIndustryType"
                                            v-model="search.industryType"
                                            :options="industryTypeOptions"
                                            text-field="englishName"
                                            value-field="id"
                                            @change="industryTypeChanged"
                                            :disabled="search.economicSector==null">
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                </b-col>
                            </b-row>
                                <b-row class="my-0">
                                    <b-col sm="12" >
                                        <label for="ddlBusinessSector">Business Sector :</label>
                                    </b-col>
                            </b-row>
                                <b-row class="my-0">
                                    <b-col sm="12" >
                                        <b-form-select
                                            id="ddlBusinessSector"
                                            v-model="search.businessSector"
                                            :options="businessSectorOptions"
                                            text-field="englishName"
                                            @change="businessSectorChanged"
                                            :disabled="search.industryType==null"
                                            value-field="id">
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </b-col>
                            </b-row>
                                <b-row class="my-0">
                                    <b-col sm="12" >
                                        <label for="ddlBusinessSubSector">Sub-Sector :</label>
                                    </b-col>
                            </b-row>
                            <b-row class="my-0">
                                    <b-col sm="12" >
                                    <b-form-select
                                            id="ddlBusinessSubSector"
                                            v-model="search.businessSubSector"
                                            :options="businessSubSectorOptions"
                                            :disabled="search.businessSector==null"
                                            text-field="englishName"
                                            value-field="id">
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </b-col>
                            </b-row>
                                <b-row class="my-0">
                                    <b-col sm="12" >
                                        <label for="ddlJobFamily">Job Family :</label>
                                    </b-col>
                            </b-row>
                            <b-row class="my-0">
                                    <b-col sm="12" >
                                        <b-form-select
                                            id="ddlJobFamily"
                                            v-model="search.jobFamily"
                                            :options="allJobFamilies"
                                            @change="jobFamilyChanged"
                                            text-field="englishName"
                                            value-field="id">
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </b-col>
                            </b-row>
                            <b-row class="my-0">
                                    <b-col sm="12" >
                                        <label for="ddlPosition">Position :</label>
                                    </b-col>
                            </b-row>
                                <b-row class="my-0">
                                    <b-col sm="12" >
                                        <b-form-select
                                            id="ddlPosition"
                                            v-model="search.position"
                                            :options="positionOptions"
                                            text-field="englishDescription"
                                            value-field="id"
                                            :disabled="search.jobFamily==null"  >
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </b-col>
                            </b-row>
                                <b-row class="my-0">
                                    <b-col sm="12" >
                                        <label>Head Count :</label>
                                    </b-col>
                            </b-row>
                                <b-row class="my-0">
                                    <b-col sm="4" >
                                        <b-form-input
                                        type="number"
                                        id="txtHeadCountFrom"
                                        v-model="search.headCountFrom" />
                                    </b-col>
                                    <b-col sm="auto" >
                                        <label for="txtHeadCountTo">To </label>
                                    </b-col>
                                    <b-col sm="4" >
                                        <b-form-input
                                        type="number"
                                        id="txtHeadCountTo"
                                        v-model="search.headCountTo" />
                                    </b-col>
                            </b-row>
                            <b-row class="my-0">
                                    <b-col sm="12" >
                                        <label>Revenue Range :</label>
                                    </b-col>
                            </b-row>
                            <b-row class="my-0">
                                    <b-col sm="4" >
                                        <b-form-input
                                        type="number"
                                        id="txtRevenueRangeFrom"
                                        v-model="search.revenueRangeFrom" />
                                    </b-col>
                                    <b-col sm="auto" >
                                        <label for="txtRevenueRangeTo">To </label>
                                    </b-col>
                                    <b-col sm="4" >
                                        <b-form-input
                                        type="number"
                                        id="txtRevenueRangeTo"
                                        v-model="search.revenueRangeTo" />
                                    </b-col>
                            </b-row>
                                <b-row class="my-0">
                                    <b-col sm="12" >
                                        <label>Years Of Experience :</label>
                                    </b-col>
                            </b-row>
                            <b-row class="my-0">
                                    <b-col sm="4" >
                                        <b-form-input
                                        type="number"
                                        id="txtExperienceYearsFrom"
                                        v-model="search.experienceYearsFrom" />
                                    </b-col>
                                    <b-col sm="auto" >
                                        <label for="txtExperienceYearsTo">To </label>
                                    </b-col>
                                    <b-col sm="4" >
                                        <b-form-input
                                        type="number"
                                        id="txtExperienceYearsTo"
                                        v-model="search.experienceYearsTo" />
                                    </b-col>
                            </b-row>
                                <b-row class="my-3">
                                    <b-col sm="12" >
                                        <b-button  type="submit" variant="primary"  block > Search </b-button>
                                    </b-col>
                            </b-row>
                        </form>
                    </b-card>
               </b-col> -->







            <!-- <v-text-field
                    v-model="searchKey"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details>
                </v-text-field> -->


            <v-data-table :items="reportData" :items-per-page=itemsPerPage size>
                <template v-slot:item="entry">
                    <!-- <div v-for="entry in entries" :key="entry.jobId"> -->
                    <b-row>
                        <b-col sm="12"> <span class="text-h5">
                                <h4><b>{{ entry.item.job }}</b></h4>
                            </span></b-col>
                    </b-row>
                    <b-row class="my-0">
                        <b-col sm="12">
                            <span class="text-h6"> Annual Basic Salary</span> </b-col>

                        <b-col sm="12">
                            <v-container fluid>
                                <b-row>
                                    <div class="ojourind">
                                        <v-btn color="ojour" normal dark
                                            v-bind:style="{ marginLeft: getOjourRank(entry.item.customerAnnualBasicSalary, entry.item.annualBasicSalary_min, entry.item.annualBasicSalary_10th_percent, entry.item.annualBasicSalary_1st_quartile, entry.item.annualBasicSalary_Median, entry.item.annualBasicSalary_Average, entry.item.annualBasicSalary_3rd_Quartile, entry.item.annualBasicSalary_90th_percent, entry.item.annualBasicSalary_max) + 'px' }">
                                            <b>{{ entry.item.customerAnnualBasicSalary | formatNumber }}</b>

                                            <div class="arrow"><img src="../../assets/media/logos/logo_sm.png" width="32">
                                            </div>
                                        </v-btn>
                                    </div>
                                </b-row>
                                <ul class="swatch">
                                    <li class="swatch__elem">Minimum <span class="used-space">{{
                                        entry.item.annualBasicSalary_min | formatNumber }}</span></li>
                                    <li class="swatch__elem">10th <span class="used-space">{{
                                        entry.item.annualBasicSalary_10th_percent | formatNumber }}</span></li>
                                    <li class="swatch__elem">1st Q <span class="used-space">{{
                                        entry.item.annualBasicSalary_1st_quartile | formatNumber }}</span></li>
                                    <li class="swatch__elem">Median <span class="used-space">{{
                                        entry.item.annualBasicSalary_Median | formatNumber }}</span></li>
                                    <li class="swatch__elem">Avg <span class="used-space">{{
                                        entry.item.annualBasicSalary_Average | formatNumber }}</span></li>
                                    <li class="swatch__elem">3rd Q <span class="used-space">{{
                                        entry.item.annualBasicSalary_3rd_Quartile | formatNumber }}</span></li>
                                    <li class="swatch__elem">90th <span class="used-space">{{
                                        entry.item.annualBasicSalary_90th_percent | formatNumber }}</span></li>
                                    <li class="swatch__elem">Maximum <span class="used-space">{{
                                        entry.item.annualBasicSalary_max | formatNumber }}</span></li>
                                </ul>

                                <!-- <b-row>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 0" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        Minimum
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 1" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        10th
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 2" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        1st Q
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 3" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        Median
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 4" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        Avg
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 5" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        3rd Q
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 6" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        90th
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 7" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        Maximum
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 8" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                </b-row> -->
                            </v-container>
                        </b-col>
                    </b-row>
                    <!-- <b-row class="my-0">
                        <b-col sm="3">
                        </b-col>
                        <b-col sm="9">
                            <v-container fluid>
                                <b-row>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 0">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{ entry.item.customerAnnualBasicSalary | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{ entry.item.annualBasicSalary_min | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 1">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{ entry.item.customerAnnualBasicSalary | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{ entry.item.annualBasicSalary_10th_percent | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 2">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{ entry.item.customerAnnualBasicSalary | formatNumber
                                            }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{ entry.item.annualBasicSalary_1st_quartile | formatNumber
                                        }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 3">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{ entry.item.customerAnnualBasicSalary | formatNumber
                                            }}</b>
                                        </v-btn>

                                    </b-col>
                                    <b-col>
                                        {{ entry.item.annualBasicSalary_Median | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 4">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{ entry.item.customerAnnualBasicSalary | formatNumber
                                            }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{ entry.item.annualBasicSalary_Average | formatNumber
                                        }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 5">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{ entry.item.customerAnnualBasicSalary | formatNumber
                                            }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{ entry.item.annualBasicSalary_3rd_Quartile | formatNumber
                                        }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 6">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{ entry.item.customerAnnualBasicSalary | formatNumber
                                            }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{ entry.item.annualBasicSalary_90th_percent | formatNumber
                                        }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 7">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{ entry.item.customerAnnualBasicSalary | formatNumber
                                            }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualBasicSalaryPosition == 8">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{ entry.item.customerAnnualBasicSalary | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{ entry.item.annualBasicSalary_max | formatNumber }}
                                    </b-col>
                                </b-row>

                            </v-container>
                        </b-col>
                    </b-row> -->


                    <b-row class="my-0 mt-3">
                        <b-col sm="12">
                            <span class="text-h6">Annual Total Guaranteed Pay</span>
                        </b-col>
                        <b-col sm="12">
                            <v-container fluid>
                                <b-row>
                                    <div class="ojourind">
                                        <v-btn color="ojour" normal dark
                                            v-bind:style="{ marginLeft: getOjourRank(entry.item.customerTotalGuaranteedPay, entry.item.annualTotalGuaranteedPay_min, entry.item.annualTotalGuaranteedPay_10th_percent, entry.item.annualTotalGuaranteedPay_1st_quartile, entry.item.annualTotalGuaranteedPay_Median, entry.item.annualTotalGuaranteedPay_Average, entry.item.annualTotalGuaranteedPay_3rd_Quartile, entry.item.annualTotalGuaranteedPay_90th_percent, entry.item.annualTotalGuaranteedPay_max) + 'px' }">
                                            <b>{{ entry.item.customerTotalGuaranteedPay | formatNumber }}</b>

                                            <div class="arrow"><img src="../../assets/media/logos/logo_sm.png" width="32">
                                            </div>
                                        </v-btn>
                                    </div>
                                </b-row>
                                <ul class="swatch">
                                    <li class="swatch__elem">Minimum <span class="used-space">{{
                                        entry.item.annualTotalGuaranteedPay_min | formatNumber }}</span></li>
                                    <li class="swatch__elem">10th <span class="used-space">{{
                                        entry.item.annualTotalGuaranteedPay_10th_percent | formatNumber }}</span></li>
                                    <li class="swatch__elem">1st Q <span class="used-space">{{
                                        entry.item.annualTotalGuaranteedPay_1st_quartile | formatNumber }}</span></li>
                                    <li class="swatch__elem">Median <span class="used-space">{{
                                        entry.item.annualTotalGuaranteedPay_Median | formatNumber }}</span></li>
                                    <li class="swatch__elem">Avg <span class="used-space">{{
                                        entry.item.annualTotalGuaranteedPay_Average | formatNumber }}</span></li>
                                    <li class="swatch__elem">3rd Q <span class="used-space">{{
                                        entry.item.annualTotalGuaranteedPay_3rd_Quartile | formatNumber }}</span></li>
                                    <li class="swatch__elem">90th <span class="used-space">{{
                                        entry.item.annualTotalGuaranteedPay_90th_percent | formatNumber }}</span></li>
                                    <li class="swatch__elem">Maximum <span class="used-space">{{
                                        entry.item.annualTotalGuaranteedPay_max | formatNumber }}</span></li>
                                </ul>

                            </v-container>
                        </b-col>
                        <!-- <b-col sm="9">
                            <v-container fluid>
                                <b-row>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 0" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        Minimum
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 1" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        10th
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 2" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        1st Q
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 3" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        Median
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 4" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        Avg
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 5" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        3rd Q
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 6" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        90th
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 7" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        Maximum
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 8" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                </b-row>

                            </v-container>
                        </b-col> -->
                    </b-row>
                    <!-- <b-row class="my-0">
                        <b-col sm="3">
                        </b-col>
                        <b-col sm="9">
                            <v-container fluid>
                                <b-row>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 0">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{ entry.item.customerTotalGuaranteedPay | formatNumber
                                            }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{ entry.item.annualTotalGuaranteedPay_min | formatNumber

                                        }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 1">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{ entry.item.customerTotalGuaranteedPay | formatNumber
                                            }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{ entry.item.annualTotalGuaranteedPay_10th_percent | formatNumber

                                        }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 2">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{ entry.item.customerTotalGuaranteedPay | formatNumber
                                            }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{ entry.item.annualTotalGuaranteedPay_1st_quartile | formatNumber
                                        }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 3">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{ entry.item.customerTotalGuaranteedPay | formatNumber
                                            }}</b>
                                        </v-btn>

                                    </b-col>
                                    <b-col>
                                        {{ entry.item.annualTotalGuaranteedPay_Median | formatNumber
                                        }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 4">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{ entry.item.customerTotalGuaranteedPay | formatNumber
                                            }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{ entry.item.annualTotalGuaranteedPay_Average | formatNumber
                                        }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 5">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{ entry.item.customerTotalGuaranteedPay | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{
                                            entry.item.annualTotalGuaranteedPay_3rd_Quartile | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 6">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerTotalGuaranteedPay | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{
                                            entry.item.annualTotalGuaranteedPay_90th_percent | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 7">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerTotalGuaranteedPay | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{
                                            entry.item.annualTotalGuaranteedPay_max | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGuaranteedPayPosition == 8">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerTotalGuaranteedPay | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                </b-row>
                            </v-container>
                        </b-col>
                    </b-row> -->


                    <b-row class="my-0 mt-3">
                        <b-col sm="12">
                            <span class="text-h6"> Annual Total Long Term Incentives + Short Term Incentives (LTI+
                                STI)</span>
                        </b-col>
                        <b-col sm="12">
                            <v-container fluid>
                                <b-row>
                                    <div class="ojourind">
                                        <v-btn color="ojour" normal dark
                                            v-bind:style="{ marginLeft: getOjourRank(entry.item.customerTotalGrossAnnual, entry.item.totalGrossAnnual_min, entry.item.totalGrossAnnual_10th_percent, entry.item.totalGrossAnnual_1st_quartile, entry.item.totalGrossAnnual_Median, entry.item.totalGrossAnnual_Average, entry.item.totalGrossAnnual_3rd_Quartile, entry.item.totalGrossAnnual_90th_percent, entry.item.totalGrossAnnual_max) + 'px' }">
                                            <b>{{ entry.item.customerTotalGrossAnnual | formatNumber }}</b>

                                            <div class="arrow"><img src="../../assets/media/logos/logo_sm.png" width="32">
                                            </div>
                                        </v-btn>
                                    </div>
                                </b-row>
                                <ul class="swatch">
                                    <li class="swatch__elem">Minimum <span class="used-space">{{
                                        entry.item.totalGrossAnnual_min | formatNumber }}</span></li>
                                    <li class="swatch__elem">10th <span class="used-space">{{
                                        entry.item.totalGrossAnnual_10th_percent | formatNumber }}</span></li>
                                    <li class="swatch__elem">1st Q <span class="used-space">{{
                                        entry.item.totalGrossAnnual_1st_quartile | formatNumber }}</span></li>
                                    <li class="swatch__elem">Median <span class="used-space">{{
                                        entry.item.totalGrossAnnual_Median | formatNumber }}</span></li>
                                    <li class="swatch__elem">Avg <span class="used-space">{{
                                        entry.item.totalGrossAnnual_Average | formatNumber }}</span></li>
                                    <li class="swatch__elem">3rd Q <span class="used-space">{{
                                        entry.item.totalGrossAnnual_3rd_Quartile | formatNumber }}</span></li>
                                    <li class="swatch__elem">90th <span class="used-space">{{
                                        entry.item.totalGrossAnnual_90th_percent | formatNumber }}</span></li>
                                    <li class="swatch__elem">Maximum <span class="used-space">{{
                                        entry.item.totalGrossAnnual_max | formatNumber }}</span></li>
                                </ul>

                                <!--
                                <b-row>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 0" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        Minimum
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 1" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        10th
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 2" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        1st Q
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 3" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        Median
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 4" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        Avg
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 5" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        3rd Q
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 6" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        90th
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 7" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        Maximum
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 8" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                </b-row> -->
                            </v-container>
                        </b-col>
                    </b-row>
                    <!-- <b-row class="my-0">
                        <b-col sm="3">
                        </b-col>
                        <b-col sm="9">
                            <v-container fluid>
                                <b-row>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 0">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerTotalGrossAnnual | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{
                                            entry.item.totalGrossAnnual_min | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 1">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerTotalGrossAnnual | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{
                                            entry.item.totalGrossAnnual_10th_percent | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 2">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerTotalGrossAnnual | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{
                                            entry.item.totalGrossAnnual_1st_quartile | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 3">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerTotalGrossAnnual | formatNumber }}</b>
                                        </v-btn>

                                    </b-col>
                                    <b-col>
                                        {{ entry.item.totalGrossAnnual_Median | formatNumber
                                        }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 4">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerTotalGrossAnnual | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{ entry.item.totalGrossAnnual_Average | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 5">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerTotalGrossAnnual | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{
                                            entry.item.totalGrossAnnual_3rd_Quartile | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 6">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerTotalGrossAnnual | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{ entry.item.totalGrossAnnual_90th_percent | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 7">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerTotalGrossAnnual | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{ entry.item.totalGrossAnnual_max | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerTotalGrossAnnualPosition == 8">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerTotalGrossAnnual | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                </b-row>
                            </v-container>
                        </b-col>
                    </b-row> -->



                    <b-row class="my-0 mt-3">
                        <b-col sm="12">
                            <span class="text-h6"> Annual Total Target Pay</span>
                        </b-col>
                        <b-col sm="12">
                            <v-container fluid>
                                <b-row>
                                    <div class="ojourind">
                                        <v-btn color="ojour" normal dark
                                            v-bind:style="{ marginLeft: getOjourRank(entry.item.customerAnnualTotalTargetPay, entry.item.annualTotalTargetPay_min, entry.item.annualTotalTargetPay_10th_percent, entry.item.annualTotalTargetPay_1st_quartile, entry.item.annualTotalTargetPay_Median, entry.item.annualTotalTargetPay_Average, entry.item.annualTotalTargetPay_3rd_Quartile, entry.item.annualTotalTargetPay_90th_percent, entry.item.annualTotalTargetPay_max) + 'px' }">
                                            <b>{{ entry.item.customerAnnualTotalTargetPay | formatNumber }}</b>

                                            <div class="arrow"><img src="../../assets/media/logos/logo_sm.png" width="32">
                                            </div>
                                        </v-btn>
                                    </div>
                                </b-row>
                                <ul class="swatch">
                                    <li class="swatch__elem">Minimum <span class="used-space">{{
                                        entry.item.annualTotalTargetPay_min | formatNumber }}</span></li>
                                    <li class="swatch__elem">10th <span class="used-space">{{
                                        entry.item.annualTotalTargetPay_10th_percent | formatNumber }}</span></li>
                                    <li class="swatch__elem">1st Q <span class="used-space">{{
                                        entry.item.annualTotalTargetPay_1st_quartile | formatNumber }}</span></li>
                                    <li class="swatch__elem">Median <span class="used-space">{{
                                        entry.item.annualTotalTargetPay_Median | formatNumber }}</span></li>
                                    <li class="swatch__elem">Avg <span class="used-space">{{
                                        entry.item.annualTotalTargetPay_Average | formatNumber }}</span></li>
                                    <li class="swatch__elem">3rd Q <span class="used-space">{{
                                        entry.item.annualTotalTargetPay_3rd_Quartile | formatNumber }}</span></li>
                                    <li class="swatch__elem">90th <span class="used-space">{{
                                        entry.item.annualTotalTargetPay_90th_percent | formatNumber }}</span></li>
                                    <li class="swatch__elem">Maximum <span class="used-space">{{
                                        entry.item.annualTotalTargetPay_max | formatNumber }}</span></li>
                                </ul>
                                <!--
                                <b-row>
                                    <b-col v-if="entry.item.customerAnnualTotalTargetPayPosition == 0" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        Minimum
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualTotalTargetPayPosition == 1" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        10th
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualTotalTargetPayPosition == 2" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        1st Q
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualTotalTargetPayPosition == 3" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        Median
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualTotalTargetPayPosition == 4" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        Avg
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualTotalTargetPayPosition == 5" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        3rd Q
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualTotalTargetPayPosition == 6" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        90th
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualTotalTargetPayPosition == 7" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                    <b-col class="my-border">
                                        Maximum
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualTotalTargetPayPosition == 8" class="my-border">
                                        <img src="../../assets/media/logos/logo_sm.png" width="32">
                                    </b-col>
                                </b-row> -->
                            </v-container>
                        </b-col>
                    </b-row>
                    <!-- <b-row class="my-0">
                        <b-col sm="3">
                        </b-col>
                        <b-col sm="9">
                            <v-container fluid>
                                <b-row>
                                    <b-col v-if="entry.item.customerAnnualTotalTargetPayPosition == 0">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerAnnualTotalTargetPay | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{
                                            entry.item.annualTotalTargetPay_min | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualTotalTargetPayPosition == 1">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerAnnualTotalTargetPay | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{
                                            entry.item.annualTotalTargetPay_10th_percent | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.customerAnnualTotalTargetPayPosition == 2">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerAnnualTotalTargetPay | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{
                                            entry.item.annualTotalTargetPay_1st_quartile | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualTotalTargetPayPosition == 3">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerAnnualTotalTargetPay | formatNumber }}</b>
                                        </v-btn>

                                    </b-col>
                                    <b-col>
                                        {{
                                            entry.item.annualTotalTargetPay_Median | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualTotalTargetPayPosition == 4">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerAnnualTotalTargetPay | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{
                                            entry.item.annualTotalTargetPay_Average | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualTotalTargetPayPosition == 5">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerAnnualTotalTargetPay | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{
                                            entry.item.annualTotalTargetPay_3rd_Quartile | formatNumber
                                        }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualTotalTargetPayPosition == 6">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerAnnualTotalTargetPay | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{
                                            entry.item.annualTotalTargetPay_90th_percent | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualTotalTargetPayPosition == 7">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerAnnualTotalTargetPay | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                    <b-col>
                                        {{
                                            entry.item.annualTotalTargetPay_max | formatNumber }}
                                    </b-col>
                                    <b-col v-if="entry.item.customerAnnualTotalTargetPayPosition == 8">
                                        <v-btn color="ojour" normal dark>
                                            <b>{{
                                                entry.item.customerAnnualTotalTargetPay | formatNumber }}</b>
                                        </v-btn>
                                    </b-col>
                                </b-row>
                            </v-container>
                        </b-col>
                    </b-row> -->
                    <!-- </div> -->
                </template>
                <template slot="no-data">
                    <h2>No Mapped positions yet</h2>
                </template>
            </v-data-table>

        </b-container>
    </div>
</template>

<script>
// import VueScrollingTable from "vue-scrolling-table"
// import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import SelectCustomer from './SelectCustomer';


export default {
    components: {
        SelectCustomer,
    },
    computed: {
        isCustomerUser() {
            return this.$store.getters.customer != 'No Customer';
        },
        allSelected() {
            return this.selected.length === this.newCustomerList.length
        },
        categories() {

            console.log('search search search searchsearch')
            const search = this.searchItem.toLowerCase()



            if (!search) {
                console.log('returning whole list.........................')
                console.log(this.newCustomerList)
                return this.newCustomerList
            }

            return this.newCustomerList.filter(item => {
                const text = item.text.toLowerCase()

                return text.indexOf(search) > -1
            })
        },
        customerNumber() {
            if (this.$store.state.userDetails.subscriptionId != -1) {
                return this.$store.state.userDetails.subscriptionId;
            }
            else if (this.search.customer > 0) {
                return this.search.customer
            }
            else
                return -1;

        },
        surveyYear() {
            return this.$store.state.userDetails.surveyYear;
        },
    },
    watch: {
        selected() {
            this.searchItem = ''
        },
    },
    data() {
        return {
            searchItem: '',
            loading: false,
            filterDialog: false,
            valid: false,
            searchKey: null,
            reportData: [],
            selected: [],
            businessSectorsAllOptions: [],
            businessSubSectorsAllOptions: [],
            countriesOptions: [],
            basicSalaryOptions: {
                hasNeedle: true,
                needleColor: 'black',
                arcColors: [],
                arcDelimiters: [10, 60, 90],
                arcPadding: 6,
                arcPaddingColor: 'white',
                arcLabels: ['35', '210', '315'],
                arcLabelFontSize: false,
                //arcOverEffect: false,
                // label options
                rangeLabel: ['0', '350'],
                centralLabel: '175',
                rangeLabelFontSize: false,
                labelsFont: 'Consolas',
            },
            filter: {
                industryType: null,
                businessSector: null,
                businessSubSector: null,
                legalStructure: null,
                experienceYearsFrom: null,
                experienceYearsTo: null,
                revenueFrom: null,
                revenueTo: null,
                headcountFrom: null,
                headcountTo: null,
                numOfServicesFrom: null,
                numOfServicesTo: null
            },
            rules: {
                experienceYearsFrom: value => (value != null && this.filter.experienceYearsTo ? Number(value) <= Number(this.filter.experienceYearsTo) : true) || 'Required Field',
                experienceYearsTo: value => (Number(value) >= Number(this.filter.numOfServicesFrom)) || 'Required Field',
                revenueFrom: value => (value != null && this.filter.revenueTo ? Number(value) <= Number(this.filter.revenueTo) : true) || 'Required Field',
                revenueTo: value => (Number(value) >= Number(this.filter.numOfServicesFrom)) || 'Required Field',
                headcountFrom: value => (value != null && this.filter.headcountTo ? Number(value) <= Number(this.filter.headcountTo) : true) || 'Required Field',
                headcountTo: value => (Number(value) >= Number(this.filter.numOfServicesFrom)) || 'Required Field',
                numOfServicesFrom: value => (value != null && this.filter.numOfServicesTo ? Number(value) <= Number(this.filter.numOfServicesTo) : true) || 'Required Field',
                numOfServicesTo: value => (Number(value) >= Number(this.filter.numOfServicesFrom)) || 'Required Field',
            },
            selectedCustomer: {
                text: 'Select Customer',
                customerNumber: -1
            },
            mockupData: [
                {
                    positionName: 'Accountant',
                    Criteria: {
                        tenthPercent: 10,
                        firstQuartile: 20,
                        median: 30,
                        average: 40,
                        thirdQuartile: 50,
                        ninethPercent: 60
                    },
                    MappedValues: {
                        annualBasicSalary: 20,
                        annualTotalGuaranteedPay: 40,
                        totalGrossAnnual: 50,
                        annualTotalTargetPay: 60
                    }
                },
                {
                    PositionName: 'HR',
                    Criteria: {
                        tenthPercent: 100,
                        firstQuartile: 200,
                        median: 300,
                        average: 400,
                        thirdQuartile: 500,
                        ninethPercent: 600
                    },
                    MappedValues: {
                        annualBasicSalary: 20,
                        annualTotalGuaranteedPay: 40,
                        totalGrossAnnual: 50,
                        annualTotalTargetPay: 60
                    }
                },
            ],
            reportFields: [
                'positionName',
                'MappedValues',
                { key: 'tenthPercent', label: '10th Percentile' },
                { key: 'firstQuart', label: '1st Quartile' },
                { key: 'median', label: 'Median' },
                { key: 'average', label: 'Average' },
                { key: 'thirdQuartile', label: '3rd Quartile' },
                { key: 'nintythPercent', label: '90th Percentile' },
            ],
            customerList: [],
            newCustomerList: [],
            itemsPerPage: 1,
            value1: 10,
            allEconomicSectors: [],
            economicSectorOptions: [],
            allIndustryTypes: [],
            industryTypeOptions: [],
            businessSectorsOptions: [],
            businessSubSectorsOptions: [],
            legalStructureOptions: [],
            allBusinessSectors: [],
            businessSectorOptions: [],
            allBusinessSubSectors: [],
            businessSubSectorOptions: [],
            allJobFamilies: [],
            jobFamilyOptions: [],
            allPositions: [],
            positionOptions: [],
            customersList: [],
            search: {
                selectedSubscription: null,
                economicSector: null,
                industryType: null,
                businessSector: null,
                businessSubSector: null,
                jobFamily: null,
                position: null,
                headCountFrom: null,
                headCountTo: null,
                revenueRangeFrom: null,
                revenueRangeTo: null,
                experienceYearsFrom: null,
                experienceYearsTo: null
            },
            margin: 10,
            positionHeaders: [
                'Annual Basic Salary',
                'Annual Total Guaranteed Pay (TGP)',
                'Total Gross Annual (STI)',
                'Annual Total Target Pay (TTP)'
            ],
            items: [
                {
                    PositionId: 1,
                    PositionName: 'Position 1',
                    tenthPercent: '10',
                    fstQuartile: '20',
                    median: '30',
                    average: '40',
                    thirdQuartile: '50',
                    ninethPercent: '60'
                },
                {
                    PositionId: 2,
                    PositionName: 'Position 2',
                    tenthPercent: '100',
                    fstQuartile: '200',
                    median: '300',
                    average: '400',
                    thirdQuartile: '500',
                    ninethPercent: '600'
                },
            ],
        }
    },
    methods: {
        customerSelected(cn) {
            let sCustomer = {
                text: cn.customerName,
                customerNumber: cn.customerNumber
            };
            this.SetselectedCustomer(sCustomer);
        },
        showErrorMessage(message) {
            this.$buefy.notification.open({
                duration: 5000,
                message: message,
                position: 'is-top-right',
                type: 'is-danger',
                hasIcon: true
            })
        },
        CustomerProfileSearch() {

        },
        getOjourRank(customerAnnualBasicSalary, annualBasicSalary_min, annualBasicSalary_10th_percent, annualBasicSalary_1st_quartile, annualBasicSalary_Median, annualBasicSalary_Average, annualBasicSalary_3rd_Quartile, annualBasicSalary_90th_percent, annualBasicSalary_max) {
            if (customerAnnualBasicSalary >= annualBasicSalary_max) {
                return 7 * 102;
            }
            if (customerAnnualBasicSalary >= annualBasicSalary_90th_percent) {
                return 6 * 102;
            }
            if (customerAnnualBasicSalary >= annualBasicSalary_3rd_Quartile) {
                return 5 * 102;
            }
            if (customerAnnualBasicSalary >= annualBasicSalary_Average) {
                return 4 * 102;
            }
            if (customerAnnualBasicSalary >= annualBasicSalary_Median) {
                return 3 * 102;
            }
            if (customerAnnualBasicSalary >= annualBasicSalary_1st_quartile) {
                return 2 * 102;
            }
            if (customerAnnualBasicSalary >= annualBasicSalary_10th_percent) {
                return 1 * 102;
            }
            if (customerAnnualBasicSalary >= annualBasicSalary_min) {
                return 0;
            }
        },
        industryTypeChanged(item) {
            this.businessSectorsOptions = [{
                "id": 0,
                "englishName": "-- all --",
                "arabicName": "-- الكل --",
            }];
            this.businessSectorsOptions.push(...this.businessSectorsAllOptions.filter(b => b.industryTypeId == item));
        },

        SetselectedCustomer(item) {
            this.selectedCustomer = item;
            this.loadCustomerPositionReport(item.customerNumber)
        },

        selections() {
            const selections = []

            for (const selection of this.selected) {
                selections.push(selection)
            }
            return selections
        },
        applyFilter() {
            let data = {
                customerNumber: this.customerNumber,
                EconomicSector: this.search.economicSector,
                IndustryType: this.search.industryType,
                BusinessSector: this.search.businessSector,
                BusinessSubSector: this.search.businessSubSector,
                JobFamily: this.search.jobFamily,
                Position: this.search.position
            }

            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/PositionReport/filter`,
                headers: { 'Content-Type': 'application/json' },
                params: data
            }).then((response) => {
                if (response.data.status == true) {
                    console.log('filter..............')
                    console.log(response.data)
                    this.reportData = response.data.positionReportData;
                    this.showReport();
                } else {
                    this.showErrorMessage(response.data.message);

                }
            });

        },
        showReport() {

            this.reportData.forEach(position => {

                // get customer position for Annual Basic Salary
                if (position.customerAnnualBasicSalary <= position.annualBasicSalary_min) {
                    position.customerAnnualBasicSalaryPosition = 0;
                }
                if (position.customerAnnualBasicSalary >= position.annualBasicSalary_min &&
                    position.customerAnnualBasicSalary <= position.annualBasicSalary_10th_percent) {
                    position.customerAnnualBasicSalaryPosition = 1;
                }
                if (position.customerAnnualBasicSalary > position.annualBasicSalary_10th_percent &&
                    position.customerAnnualBasicSalary <= position.annualBasicSalary_1st_quartile) {
                    position.customerAnnualBasicSalaryPosition = 2;
                }
                if (position.customerAnnualBasicSalary > position.annualBasicSalary_1st_quartile &&
                    position.customerAnnualBasicSalary <= position.annualBasicSalary_Median) {
                    position.customerAnnualBasicSalaryPosition = 3;
                }
                if (position.customerAnnualBasicSalary > position.annualBasicSalary_Median &&
                    position.customerAnnualBasicSalary <= position.annualBasicSalary_Average) {
                    position.customerAnnualBasicSalaryPosition = 4;
                }
                if (position.customerAnnualBasicSalary > position.annualBasicSalary_Average &&
                    position.customerAnnualBasicSalary <= position.annualBasicSalary_3rd_Quartile) {
                    position.customerAnnualBasicSalaryPosition = 5;
                }
                if (position.customerAnnualBasicSalary > position.annualBasicSalary_3rd_Quartile &&
                    position.customerAnnualBasicSalary <= position.annualBasicSalary_90th_percent) {
                    position.customerAnnualBasicSalaryPosition = 6;
                }
                if (position.customerAnnualBasicSalary > position.annualBasicSalary_90th_percent &&
                    position.customerAnnualBasicSalary <= position.annualBasicSalary_max) {
                    position.customerAnnualBasicSalaryPosition = 7;
                }
                if (position.customerAnnualBasicSalary > position.annualBasicSalary_max) {
                    position.customerAnnualBasicSalaryPosition = 8;
                }

                //get the customer position for Total guaranteed pay

                if (position.customerTotalGuaranteedPay <= position.annualTotalGuaranteedPay_min)
                    position.customerTotalGuaranteedPayPosition = 0;
                if (position.customerTotalGuaranteedPay >= position.annualTotalGuaranteedPay_min &&
                    position.customerTotalGuaranteedPay <= position.annualTotalGuaranteedPay_10th_percent)
                    position.customerTotalGuaranteedPayPosition = 1;
                if (position.customerTotalGuaranteedPay > position.annualTotalGuaranteedPay_10th_percent &&
                    position.customerTotalGuaranteedPay <= position.annualTotalGuaranteedPay_1st_quartile)
                    position.customerTotalGuaranteedPayPosition = 2;
                if (position.customerTotalGuaranteedPay > position.annualTotalGuaranteedPay_1st_quartile &&
                    position.customerTotalGuaranteedPay <= position.annualTotalGuaranteedPay_Median)
                    position.customerTotalGuaranteedPayPosition = 3;
                if (position.customerTotalGuaranteedPay > position.annualTotalGuaranteedPay_Median &&
                    position.customerTotalGuaranteedPay <= position.annualTotalGuaranteedPay_Average)
                    position.customerTotalGuaranteedPayPosition = 4;
                if (position.customerTotalGuaranteedPay > position.annualTotalGuaranteedPay_Average &&
                    position.customerTotalGuaranteedPay <= position.annualTotalGuaranteedPay_3rd_Quartile)
                    position.customerTotalGuaranteedPayPosition = 5;
                if (position.customerTotalGuaranteedPay > position.annualTotalGuaranteedPay_3rd_Quartile &&
                    position.customerTotalGuaranteedPay <= position.annualTotalGuaranteedPay_90th_percent)
                    position.customerTotalGuaranteedPayPosition = 6;
                if (position.customerTotalGuaranteedPay > position.annualTotalGuaranteedPay_90th_percent &&
                    position.customerTotalGuaranteedPay <= position.annualTotalGuaranteedPay_max)
                    position.customerTotalGuaranteedPayPosition = 7;
                if (position.customerTotalGuaranteedPay > position.annualTotalGuaranteedPay_max) {
                    position.customerTotalGuaranteedPayPosition = 8;
                }

                //get the customer position for total target pay
                if (position.customerAnnualTotalTargetPay <= position.annualTotalTargetPay_min)
                    position.customerAnnualTotalTargetPayPosition = 0;
                if (position.customerAnnualTotalTargetPay >= position.annualTotalTargetPay_min &&
                    position.customerAnnualTotalTargetPay <= position.annualTotalTargetPay_10th_percent)
                    position.customerAnnualTotalTargetPayPosition = 1;
                if (position.customerAnnualTotalTargetPay > position.annualTotalTargetPay_10th_percent &&
                    position.customerAnnualTotalTargetPay <= position.annualTotalTargetPay_1st_quartile)
                    position.customerAnnualTotalTargetPayPosition = 2;
                if (position.customerAnnualTotalTargetPay > position.annualTotalTargetPay_1st_quartile &&
                    position.customerAnnualTotalTargetPay <= position.annualTotalTargetPay_Median)
                    position.customerAnnualTotalTargetPayPosition = 3;
                if (position.customerAnnualTotalTargetPay > position.annualTotalTargetPay_Median &&
                    position.customerAnnualTotalTargetPay <= position.annualTotalTargetPay_Average)
                    position.customerAnnualTotalTargetPayPosition = 4;
                if (position.customerAnnualTotalTargetPay > position.annualTotalTargetPay_Average &&
                    position.customerAnnualTotalTargetPay <= position.annualTotalTargetPay_3rd_Quartile)
                    position.customerAnnualTotalTargetPayPosition = 5;
                if (position.customerAnnualTotalTargetPay > position.annualTotalTargetPay_3rd_Quartile &&
                    position.customerAnnualTotalTargetPay <= position.annualTotalTargetPay_90th_percent)
                    position.customerAnnualTotalTargetPayPosition = 6;
                if (position.customerAnnualTotalTargetPay > position.annualTotalTargetPay_90th_percent &&
                    position.customerAnnualTotalTargetPay <= position.annualTotalTargetPay_max)
                    position.customerAnnualTotalTargetPayPosition = 7;
                if (position.customerAnnualTotalTargetPay > position.annualTotalTargetPay_max) {
                    position.customerAnnualTotalTargetPayPosition = 8;
                }


                //get the customer position for total Gross Annual
                if (position.customerTotalGrossAnnual <= position.totalGrossAnnual_min)
                    position.customerTotalGrossAnnualPosition = 0;
                if (position.customerTotalGrossAnnual >= position.totalGrossAnnual_min &&
                    position.customerTotalGrossAnnual <= position.totalGrossAnnual_10th_percent)
                    position.customerTotalGrossAnnualPosition = 1;
                if (position.customerTotalGrossAnnual > position.totalGrossAnnual_10th_percent &&
                    position.customerTotalGrossAnnual <= position.totalGrossAnnual_1st_quartile)
                    position.customerTotalGrossAnnualPosition = 2;
                if (position.customerTotalGrossAnnual > position.totalGrossAnnual_1st_quartile &&
                    position.customerTotalGrossAnnual <= position.totalGrossAnnual_Median)
                    position.customerTotalGrossAnnualPosition = 3;
                if (position.customerTotalGrossAnnual > position.totalGrossAnnual_Median &&
                    position.customerTotalGrossAnnual <= position.totalGrossAnnual_Average)
                    position.customerTotalGrossAnnualPosition = 4;
                if (position.customerTotalGrossAnnual > position.totalGrossAnnual_Average &&
                    position.customerTotalGrossAnnual <= position.totalGrossAnnual_3rd_Quartile)
                    position.customerTotalGrossAnnualPosition = 5;
                if (position.customerTotalGrossAnnual > position.totalGrossAnnual_3rd_Quartile &&
                    position.customerTotalGrossAnnual <= position.totalGrossAnnual_90th_percent)
                    position.customerTotalGrossAnnualPosition = 6;
                if (position.customerTotalGrossAnnual > position.totalGrossAnnual_90th_percent &&
                    position.customerTotalGrossAnnual <= position.totalGrossAnnual_max)
                    position.customerTotalGrossAnnualPosition = 7;
                if (position.customerTotalGrossAnnual > position.totalGrossAnnual_max) {
                    position.customerTotalGrossAnnualPosition = 8;
                }
            });



        },
        economicSectorChanged(item) {
            if (this.allIndustryTypes != null && this.allIndustryTypes.length > 0) {
                this.industryTypeOptions = this.allIndustryTypes.filter(c => c.economicSectorId == item);
            }
        },
        // industryTypeChanged(item){
        //     if(this.allBusinessSectors!=null && this.allBusinessSectors.length > 0){
        //         this.businessSectorOptions = this.allBusinessSectors.filter(c=>c.industryTypeId == item);
        //     }
        // },
        businessSectorChanged(item) {
            this.businessSubSectorsOptions = [{
                "id": 0,
                "englishName": "-- all --",
                "arabicName": "-- الكل --",
            }];
            this.businessSubSectorsOptions.push(...this.businessSubSectorsAllOptions.filter(b => b.businessSectorId == item));
        },
        jobFamilyChanged(item) {
            if (this.allPositions != null && this.allPositions.length > 0) {
                this.positionOptions = this.allPositions.filter(c => c.jobFamily == item);
            }
        },
        validateFilter() {
            if (this.$refs.settingsForm.validate()) {
                this.ApplyFliter();
            }
        },
        ApplyFliter() {


            // if(this.selectedCustomer == null || this.selectedCustomer == -1){
            //     console.log('customer number');
            //     console.log(this.customerNumber)
            // }
            // else{
            //     console.log('customer number');
            //     console.log(this.selectedCustomer.customerNumber)
            // }
            console.log('filter hereeeeeeeeeeeeeeeeeeeeeeeeee');
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/PositionReport/positionsWithFilter`,
                params: {
                    customerNumber: this.selectedCustomer.customerNumber,
                    industryType: this.filter.industryType,
                    businessSector: this.filter.businessSector,
                    businessSubSector: this.filter.businessSubSector,
                    legalStructure: this.filter.legalStructure,
                    experienceYearsFrom: this.filter.experienceYearsFrom,
                    experienceYearsTo: this.filter.experienceYearsTo,
                    revenueFrom: this.filter.revenueFrom,
                    revenueTo: this.filter.revenueTo,
                    headcountFrom: this.filter.headcountFrom,
                    headcountTo: this.filter.headcountTo,
                    numOfServicesFrom: this.filter.numOfServicesFrom,
                    numOfServicesTo: this.filter.numOfServicesTo
                }
            }).then((response) => {
                if (response.data.status == true) {
                    this.reportData = response.data.positionReportData;
                    this.showReport();
                } else {
                    this.showErrorMessage(response.data.message);

                }
            });


        },
        loadCustomerPositionReport(customerNumber) {
            let selectedCustomer = null;
            if (customerNumber == null) {
                selectedCustomer = this.customerNumber
            }
            else {
                selectedCustomer = customerNumber
            }
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/PositionReport/positions`,
                params: {
                    customerNumber: selectedCustomer,
                }
            }).then((response) => {
                if (JSON.parse(response.data.status) == true) {
                    this.reportData = response.data.positionReportData;
                    this.showReport();
                } else {
                    this.reportData = [];
                }
            });
        },
        getLookups() {
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerProfile/lookups`,
            })
                .then((response) => {
                    if (response.data.legalStructures) {
                        this.legalStructureOptions = response.data.legalStructures.map(v => ({
                            value: v.id,
                            text: v.englishName
                        }));
                    }

                    this.industryTypeOptions = [{
                        "id": 0,
                        "englishName": "-- all --",
                        "arabicName": "-- الكل --",
                    }];
                    if (response.data.industryTypes) {
                        this.industryTypeOptions.push(...response.data.industryTypes);

                    }
                    this.businessSectorsAllOptions = [{
                        "id": 0,
                        "englishName": "-- all --",
                        "arabicName": "-- الكل --",
                    }];
                    if (response.data.businessSectors) {
                        this.businessSectorsAllOptions.push(...response.data.businessSectors);

                    }

                    this.businessSubSectorsAllOptions = [{
                        "id": 0,
                        "englishName": "-- all --",
                        "arabicName": "-- الكل --",
                    }];
                    if (response.data.businessSubSectors) {
                        this.businessSubSectorsAllOptions.push(...response.data.businessSubSectors);

                    }
                    this.countriesOptions = [{
                        "id": 0,
                        "englishName": "-- all --",
                        "arabicName": "-- الكل --",
                    }];
                    if (response.data.countries) {
                        this.countriesOptions.push(...response.data.countries);
                    }
                });
        }
    },
    mounted() {
        this.getLookups();
        if (this.customerNumber > 0) {
            this.loadCustomerPositionReport();
        }
        else {

            // load customers
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/PositionMapping/Searchlookups`,
            })
                .then((response) => {
                    console.log('response..............')
                    console.log(response)
                    // console.log(response.data.customersList);
                    this.customerList = response.data.customersList;
                    this.newCustomerList = response.data.customersList
                        .map(customer => ({
                            text: customer.customerName,
                            icon: 'mdi-map-marker',
                            customerNumber: customer.customerNumber
                        }))
                    // this.surveyYears = response.data.surveyYears;
                    // this.search.surveyYear = this.surveyYear;
                    // this.PositionsInSearch = response.data.unmappedPositions.map(p=>({
                    //     id:p.titleId,
                    //     name: p.titleName,
                    //     PositionTypeId: p.positionTypeId,
                    //     PositionTypeName : p.positionTypeName
                    // }) );
                });

            // this.getPositions(1)


            //  this.showCustomers = true;
            //   this.$axios({
            //     method: 'get',
            //     url: `${this.$baseURL}/PositionReport/customers`,
            // })
            // .then((response)=>{
            //     console.log(response.data);
            //     if(JSON.parse(response.data.status) == true){
            //        this.customersList = response.data.customersList
            //     }
            // });
        }

        // Load Lookups
        // this.$axios({
        //         method: 'get',
        //         url: `${this.$baseURL}/PositionReport/lookups`,
        //     })
        //     .then((response)=>{
        //         // console.log(response.data.lookups);
        //         if(JSON.parse(response.data.status) == true){
        //             this.allEconomicSectors = response.data.lookups.economicSectors
        //             this.allIndustryTypes = response.data.lookups.industryTypes
        //             this.allBusinessSectors = response.data.lookups.businessSectors
        //             this.allBusinessSubSectors = response.data.lookups.businessSubSectors
        //             this.allJobFamilies = response.data.lookups.jobFamilies
        //             this.allPositions = response.data.lookups.jobTitles
        //         }
        //     });
    }
}
</script>

<style scoped>
/*
.v-card {
  display: flex !important;
  flex-direction: row;
} */

/* .v-card__text {
  flex-grow: 2;
  overflow: auto;
} */
.my-border {
    /* border-left: 2px solid black;
  border-right: 2px solid black;
  border-top: 2px solid black; */
    border-bottom: 2px solid black;
}

table.scrolling .w1 {
    width: 10em;
    min-width: 10em;
    max-width: 10em;
    height: 6.5em;
}

table.scrolling .w2 {
    width: 15em;
    min-width: 15em;
    max-width: 15em;
    height: 6.5em;
}

table.scrolling .w3 {
    width: 40em;
    min-width: 40em;
    max-width: 40em;
    height: 6.5em;
}

.ojourind {

    margin: 1em auto;
    width: 850px;
    display: block;
}

.swatch {

    margin: 0;
    padding: 0;
    margin: 3em auto;
    list-style-type: none;
    width: 850px;
    display: block;
    position: relative;
}

.swatch::before {

    content: '';
    width: 100%;
    height: 10px;
    outline: 0px solid #000;
    position: absolute;
    top: -20px;
    left: 0;
    border-radius: 2px;

    background-image: linear-gradient(90deg,
            #8bcf69 12%,
            #e6d450 12%,
            #e6d450 24%,
            #f28f68 24%,
            #f28f68 36%,
            #cf82bf 36%,
            #cf82bf 48%,
            #719fd1 48%,
            #8bcf69 60%,
            #e6d450 60%,
            #e6d450 72%,
            #f28f68 72%,
            #f28f68 84%,
            #cf82bf 84%,
            #719fd1 100%);

}

.swatch__elem {

    outline: 0px solid #c00;
    float: left;
    width: 106px;
    padding-left: 5px;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.used-space {

    display: block;
    line-height: 2;
    font-size: 85%;
    color: #666;
}

.meter-gauge {

    border: 1px solid #ccc;
    border-radius: 3px;

    background-color: whiteSmoke;
    box-shadow: 0 5px 5px -5px #333 inset;

    width: 550px;
    height: 25px;

    display: block;
}

.meter-gauge>span {

    height: inherit;
    box-shadow: 0 5px 5px -5px #999 inset;

    background-color: blue;
    background-image: linear-gradient(90deg,
            #8bcf69 5%,
            #e6d450 5%,
            #e6d450 15%,
            #f28f68 15%,
            #f28f68 55%,
            #cf82bf 55%,
            #cf82bf 95%,
            #719fd1 95%,
            #719fd1 100%);

    background-size: 100% 100%;

    display: block;
    text-indent: -9999px;
}

div.arrow {
    box-sizing: border-box;
    position: absolute;
    z-index: 5;
    left: 4px;
    top: 17px;
}
</style>